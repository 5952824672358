<script setup>
import {onMounted, ref} from 'vue';

defineProps({
	modelValue: String,
});

defineEmits(['update:modelValue']);

const input = ref(null);

onMounted(() => {
	if (input.value.hasAttribute('autofocus')) {
		input.value.focus();
	}
});

defineExpose({focus: () => input.value.focus()});
</script>

<template>
	<input
		ref="input"
		:value="modelValue"
		class="border-dark-300 dark:border-dark-700 dark:bg-dark-100 dark:text-dark-800 focus:border-primary-500 p-2.5 dark:focus:border-primary-600 focus:ring-primary-500 dark:focus:ring-primary-600 rounded-md shadow-sm"
		@input="$emit('update:modelValue', $event.target.value)"
	>
</template>
